import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const cta = () => {
  return (
    <section className="content darkcta" id="cta">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 wow fadeInLeft">
            <div className="title">
              <h6 className="third-color mb-3">
                Su proyecto en las mejores manos
              </h6>
              <h2 className="mb-4">Desarrollamos su proyecto digital</h2>
              <p>
                Ofrecemos resultados de calidad, garantizamos el éxito de las
                soluciones digitales y las aplicaciones móviles que creamos.
              </p>
              <Link
                to="/contact"
                className="btn btn-large btn-gradient btn-rounded mt-4"
              >
                Solicitar reunión
              </Link>
            </div>
          </div>
          <div className="col-lg-6 wow fadeInRight col-sm-12 d-sm-none d-lg-block d-md-none">
            <StaticImage src="../../images/cta-mobile.png" alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default cta;
