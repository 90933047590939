import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import BlogCard from "../content/BlogPreview";

const Blog = () => {
  const { allContentfulBlogPost } = useStaticQuery(
    graphql`
      {
        allContentfulBlogPost(limit: 3) {
          nodes {
            slug
            id
            title
            excerpt {
              excerpt
            }
            featuredImage {
              gatsbyImageData(formats: WEBP, width: 500, height: 400)
              title
            }
            publishDate(formatString: "MMMM D, YYYY", locale: "es")
          }
        }
      }
    `
  );

  const posts = allContentfulBlogPost.nodes;

  return (
    <section id="blog" className="bg-light">
      <div className="container">
        <div className="row wow fadeIn">
          <div className="col-md-12 text-center">
            <div className="title d-inline-block">
              <h6 className="mb-3">El conocimiento se comparte</h6>
              <h2 className="gradient-text1 mb-3">Nuestro Blog</h2>
              <p>
                Nos mantenemos en constante aprendizaje, por eso ponemos a
                disposición un espacio donde encontrará recomendaciones, hacks y
                novedades.
              </p>
            </div>
          </div>
        </div>
        {posts.map((post) => (
          <BlogCard
            key={post.id}
            title={post.title}
            excerpt={post.excerpt.excerpt}
            image={post.featuredImage.gatsbyImageData}
            alt={post.featuredImage.title}
            date={post.publishDate}
            slug={post.slug}
          />
        ))}
      </div>
    </section>
  );
};

export default Blog;
