import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import Hero from "../components/Hero";
import About from "../components/sections/About";
import CaseStudy from "../components/sections/CaseStudy";
import Cta from "../components/sections/Cta";
import Blog from "../components/sections/Blog.js";
import Contact from "../components/sections/ContactInfo";
import Seo from "../components/SEO";


const IndexPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>RöDigital</title>
        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.15.4/css/all.css" integrity="sha384-DyZ88mC6Up2uqS4h/KRgHuoeGwBcD4Ng9SiP4dIRy0EXTlnuz47vAwmeGwVChigm" crossorigin="anonymous" />
        <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/v2.js?pre=1"></script>
      </Helmet>
      <Hero />
      <About />
      <CaseStudy />
      <Cta />
      <Blog />
      <Contact />
    </Layout>
  );
};

export default IndexPage;

export const Head = () => <Seo title="RöDigital" />;
