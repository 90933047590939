import React from "react";
import { Link } from "gatsby";
import video from "../images/video/video.mp4";

const Hero = () => {
  return (
    <section id="home">
      <div className="gradient-bg1 bg-overlay opacity-9 z-index-1"></div>
      <video
        className="d-block min-vh-100 min-vh-100 min-vw-100 hero-video"
        autoPlay
        muted
        loop
      >
        <source src={video} type="video/mp4" />
      </video>
      <div className="caption">
        <p className="text-white">
          Desarrollo de software y soluciones digitales.
        </p>
        <h1> E-commerce, Websites y Aplicaciones Móviles.</h1>
        <p className="text-white">
          Somos digitales, su guía y mejor aliado para lograr sus objetivos en
          el mundo digital. Nuestro equipo de expertos no le teme a los retos,
          nos ponemos la camiseta y le ofrecemos soluciones que se adaptan a sus
          necesidades.
        </p>
        <Link to="/contact" className="btn btn-large btn-rounded btn-white">
          Conversemos
        </Link>
      </div>
    </section>
  );
};

export default Hero;
