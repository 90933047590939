import React from "react";
import Video from "./about/AboutVideo";

const about = () => {
  return (
    <section id="about" className="pb-0">
      <div className="container pb-5">
        {/* <!--About--> */}
        <div className="row align-items-center wow fadeIn">
          <div className="col-md-5">
            <div className="title">
              <h6 className="third-color mb-3">
                Soluciones digitales - Costa Rica
              </h6>
              <h2>RöDigital - Ser Digital</h2>
            </div>
            <p>
              Somos parte de
              <a href="https://www.paralleldevs.com"> ParallelDevs</a>, agencia
              de soluciones digitales con más de 12+ años trabajando en el
              mercado internacional. Con los cambios vividos en los últimos años
              decidimos que es el momento perfecto para poner a disposición de
              pequeñas, medianas y grandes empresas nacionales la experiencia
              que hemos obtenido, pero esto no lo queríamos hacer como
              <a href="https://www.paralleldevs.com"> ParallelDevs</a>, queríamos
              una marca que representara nuestro ADN Costarisence y tecnológico. Rö significa
              <b> ser</b> en lengua indígena de Costa Rica, y es eso lo que
              somos, nativos digitales.
            </p>
          </div>
          <div className="col-md-7 mb-4">
            <div className="separator sepBr-2 mb-4"></div>

            <Video
              className="about-video d-sm-none d-md-block"
              videoSrcURL="https://www.youtube.com/embed/EgfshxuODVo?autoplay=1&mute=1&controls=0&rel=0&playlist=EgfshxuODVo&loop=1&showinfo=1&autohide=1"
              videoTitle="Official Music Video on YouTube"
            />

          </div>
        </div>
      </div>
      <div className="container-fluid fullWidth">
        <div className="row">
          <div className="separator"></div>
          <div className="container d-flex justify-content-center">
            <div className="row container">
              <div className="col-md-3">
                <div className="iconHolder">
                  <i className="fas fa-shopping-cart"></i>
                </div>
              </div>
              <div className="col-md-3">
                <div className="iconHolder">
                  <i className="fas fa-laptop"></i>
                </div>
              </div>
              <div className="col-md-3">
                <div className="iconHolder">
                  <i className="fas fa-mobile-alt"></i>
                </div>
              </div>
              <div className="col-md-3">
                <div className="iconHolder">
                  <i className="fas fa-code"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        
        <div className="row mt-lg-5 servicesHomeBlock  mb-3 pb-5">
        <div className="separator-two"></div>
          <div className="col-md-3 wow fadeInLeft">
            <div className="feature-box">
            <div className="only-icon mt-3">
                <div className="iconHolder">
                  <i className="fas fa-shopping-cart"></i>
                </div>
              </div>
              <h6 className="mb-4">Desarrollo E-commerce</h6>
              <p>
                Ofrecemos soluciones e-commerce, ya sea que busque desarrollar
                su tienda online desde cero u optimizar la que tiene, nuestra
                experiencia nos permite asesorarle para brindarle la mejor
                opción no sólo en calidad sino en economía. Tenemos experiencia
                trabajando con plataformas como WordPress (Woo-commerce), Drupal
                y Magento.
              </p>
            </div>
          </div>
          <div className="col-md-3 wow fadeInUp">
            <div className="feature-box">
              <div className="only-icon mt-3">
                <div className="iconHolder">
                <i className="fas fa-laptop"></i>
                </div>
              </div>
              <h6 className="mb-4">Desarrollo de Sitios Web</h6>
              <p>
                Con amplia experiencia en el desarrollo de páginas web, le
                ofrecemos un servicio integral, empezando por diseño UX, pasando
                por desarrollo, control de calidad y lanzamiento; pero no
                quedamos ahí, le brindamos soporte en caso de actualizaciones,
                cambios o mejoras a la plataforma.
              </p>
            </div>
          </div>
          <div className="col-md-3 wow fadeInRight">
            <div className="feature-box">
            <div className="only-icon mt-3">
                <div className="iconHolder">
                  <i className="fas fa-mobile-alt"></i>

                </div>
              </div>
              <h6 className="mb-4">Desarrollo Aplicaciones Móviles</h6>
              <p>
                Nuestra experiencia desarrollando aplicaciones móviles nos
                permite ser tu mejor aliado, sacamos adelante tu proyecto sin
                importar si se trata de desarrollar una app para startups, de
                servicio o una aplicación corporativa.
              </p>
            </div>
          </div>
          <div className="col-md-3 wow fadeInRight">
            <div className="feature-box">
            <div className="only-icon mt-3">
                <div className="iconHolder">
                  <i className="fas fa-code"></i>

                </div>
              </div>
              <h6 className="mb-4">Desarrollo de Software</h6>
              <p>
                Nuestra experiencia desarrollando aplicaciones móviles nos
                permite ser tu mejor aliado, sacamos adelante tu proyecto sin
                importar si se trata de desarrollar una app para startups, de
                servicio o una aplicación corporativa.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default about;
