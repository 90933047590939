import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import SocialMedia from "./SocialMedia";
import Gotform from "../sections/contact/Gotform";

const contact = () => {
  return (
    <section id="contact" className="p-0">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-md-6 p-0">
            <div className="address-box title mb-0 bg-img4">
              <div className="bg-overlay gradient-bg1 opacity-8" />
              <StaticImage className="bg-cover-image" src="../../images/bg.jpg" alt="" />
              <div className="address-text text-center text-white position-relative wow fadeInUp">
                <h6 className="mb-3">E-commerce, Websites y Aplicaciones Móviles.</h6>
                <h2 className="mb-4">RöDigital</h2>
                <p className="mb-3 text-white">San José, Costa Rica</p>
                <p className="mb-3 text-white">Teléfono : +506 4001 3267</p>
                <p className="mb-3 text-white">Mail : info@rodigital.io</p>
                <p className="mb-3 text-white">
                  Horario: Lunes-Viernes: 8am a 5pm
                </p>
                <SocialMedia />
              </div>
            </div>
          </div>
          <div className="col-md-6 p-0 wow fadeInUp">
            <div className="contact-form left">
              <Gotform />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default contact;
