import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

export default function caseStudiesPreview({
  title,
  excerpt,
  image,
  slug,
  alt,
}) {
  return (
    <div className="col-md-4 wow fadeInLeft">
      <Link to={`/casos-de-estudio/${slug}`}>
        <div className="blog-image text-right hover-effect wow fadeInRight mb-3">
          <GatsbyImage image={image} alt="Caso de estudio imágen destacada" />
        </div>
      </Link>

      <div className="feature-box">
        <h6 className="blog-title mb-4">
          <Link key={slug} to={`/casos-de-estudio/${slug}`}>
            {title}
          </Link>
        </h6>
        <p>{excerpt}</p>
      </div>
      <Link
        className="btn btn-large btn-gradient btn-rounded mt-4"
        key={slug}
        to={`/casos-de-estudio/${slug}`}
      >
        Ver más
      </Link>
    </div>
  );
}
