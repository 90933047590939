import React, { useState } from "react"
import axios from "axios";
const Gotform = ({ data }) => {

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null
  });
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg }
    });
    if (ok) {
      form.reset();
    }
  };
  const handleOnSubmit = e => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    axios({
      method: "post",
      url: "https://getform.io/f/b8235e3f-eeff-41ab-8711-edb116331877",
      data: new FormData(form)
    })
      .then(r => {
        handleServerResponse(true, "Gracias! Pronto estaremos en contacto con usted.", form);
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form);
      });
  };
  return (
    <div className="">
      <form onSubmit={handleOnSubmit} className="contact-form">
        <div className="row">
          <div className="form-group col-lg-6">
            <input type="text" name="Nombre" className="form-control" id="Nombre" placeholder="Ingrese su nombre" required="required" />
          </div>
          <div className="form-group col-lg-6">
            <input type="email" name="email" className="form-control" id="Email" aria-describedby="emailHelp" placeholder="Ingrese su Email" />
          </div>
          <div className="form-group col-lg-6">
            <input type="text" name="empresa" className="form-control" id="Empresa" placeholder="Ingrese el nombre de su empresa" />
          </div>
          <div className="form-group col-lg-6">
            <input type="text" name="Telefono" className="form-control" id="Telefono" placeholder="Teléfono de contacto" />
          </div>
          <div className="form-group col-lg-6">
            <input type="text" name="celular" className="form-control" id="Celular" placeholder="Celular" />
          </div>
          <div className="form-group col-lg-6">
            <select className="form-control" id="exampleFormControlSelect1" name="service" required="required">
              <option value="Interesado en" defaultValue hidden>Interesado en</option>
              <option>UX/UI</option>
              <option>Desarrollo Web</option>
              <option>Desarrollo de Sofware</option>
              <option>Desarrollo de Aplicaciones Moviles</option>
            </select>
          </div>
          <div className="form-group">
            <textarea className="form-control contactformtextarea" placeholder="Por favor déjenos saber como podemos servirle" id="message" name="message"></textarea>
          </div>
          <div className="col-sm-12">
            <button type="submit" className="btn btn-large btn-gradient mt-4" id="submit_btn" disabled={serverState.submitting}><i className="fa fa-spinner fa-spin mr-2 d-none" aria-hidden="true"></i> <span>Contactar</span></button>
          </div>
          {serverState.status && (
            <p className={!serverState.status.ok ? "errorMsg" : "okMesg alert alert-success mb-5"}>
              {serverState.status.msg}
            </p>
          )}
        </div>
      </form>
    </div>
  );
};

export default Gotform;