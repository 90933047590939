import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import CaseStudiesPreview from "../content/CaseStudyPreview.js";

const CaseStudy = () => {
  const { allContentfulCasoDeEstudio } = useStaticQuery(
    graphql`
      {
        allContentfulCasoDeEstudio(limit: 3) {
          nodes {
            id
            slug
            title
            excerpt {
              excerpt
            }
            featuredImage {
              gatsbyImageData(formats: WEBP, width: 356, height: 283)
              title
            }
            publishDate(formatString: "MMMM D, YYYY", locale: "es")
          }
        }
      }
    `
  );

  const posts = allContentfulCasoDeEstudio.nodes;

  return (
    <section id="portfolio" className="bg-light">
      <div className="container">
        {/* <!--About--> */}
        <div className="row align-items-center">
          <div className="col-md-6 wow fadeInLeft">
            <div className="title">
              <h6 className="third-color mb-3">Portafolio recientes</h6>
              <h2>Nuestro Trabajo</h2>
            </div>
          </div>
          <div className="col-md-6 mb-4 wow fadeInRight">
            <p>
              Somos fieles creyentes de que los hechos hablan más que las
              palabras, no le tememos a los retos y eso queda demostrado en cada
              uno de nuestros casos de estudios sobre los proyectos que hemos
              realizado.
              <br />
              <br />
              Nos comprometemos con cada uno de nuestros clientes para llevar a
              cabo su proyecto con entrega, dedicación y calidad.
            </p>
          </div>
        </div>
        <div className="row mt-lg-5">
          {posts.map((post) => (
            <CaseStudiesPreview
              key={post.id}
              title={post.title}
              excerpt={post.excerpt.excerpt}
              image={post.featuredImage.gatsbyImageData}
              alt={post.featuredImage.title}
              slug={post.slug}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default CaseStudy;
