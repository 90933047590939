import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

export default function BlogCard({ date, title, excerpt, image, slug }) {
  return (
    <div className="blog-preview row align-items-center mb-5">
      <div className="col-md-6 wow fadeInRight">
        <div className="blog-text">
          <h6 className="third-color mb-3 text-capitalize">{date}</h6>
          <h2 className="blog-title">
            <Link key={slug} to={`/blog/${slug}`}>
              {title}
            </Link>
          </h2>
          <p>{excerpt}</p>
          <Link
            className="btn btn-large btn-gradient btn-rounded mt-4"
            key={slug}
            to={`/blog/${slug}`}
          >
            Ver más
          </Link>
        </div>
      </div>
      <div className="col-md-6">
        <Link to={`/blog/${slug}`}>
          <div className="blog-image wow hover-effect fadeInLeft">
            <GatsbyImage image={image} alt="Blog imágen destacada" />
          </div>
        </Link>
      </div>
    </div>
  );
}
